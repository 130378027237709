import { Notification } from 'element-ui';

const globalNotices = object => {
  let list = document.getElementsByClassName('el-form-item__label');
  let finalResult = new Set();
  Array.from(list).forEach(({ htmlFor, innerText }) => {
    if (object[htmlFor]) finalResult.add(innerText);
  });
  return [...finalResult].toString();
};
export const requiredReminder = val => {
  Notification({
    title: '校验提醒',
    dangerouslyUseHTMLString: true,
    message: `请检查
                <span style='color: red'> 
                  <ins>
                    <strong>
                      <em>${globalNotices(val)}</em>
                    </strong>
                  </ins>
                </span>
              !`
  });
  return false;
};
export const requiredReminderAndUGrid = (val, val2) => {
  Notification({
    title: '校验提醒',
    dangerouslyUseHTMLString: true,
    message: `请检查
                <span style='color: red'> 
                  <ins>
                    <strong>
                      <em>${globalNotices(val) + val2}</em>
                    </strong>
                  </ins>
                </span>
              !`
  });
  return false;
};
