<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('fpayForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="fpayForm" :model="fpayForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收款单位" prop="inst_cname">
            <el-input disabled v-model="fpayForm.inst_cname" maxlength="20" show-word-limit placeholder="请填写收款单位">
              <template slot="append">
                <el-link type="primary" @click="dialogTableVisible = true">选择</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用经办人" prop="cust_stff_id">
            <el-select v-model="fpayForm.cust_stff_id" clearable filterable placeholder="请选择采购经办人" size="small" @change="custStffChange">
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="申请日期:" prop="fpay_date">
            <el-date-picker v-model="fpayForm.fpay_date" type="date" placeholder="选择申请日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="付款金额" prop="fpay_total">
            <el-input disabled v-model="fpayForm.fpay_total" maxlength="13" show-word-limit placeholder="请填写付款金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用名称" prop="fpay_name">
            <el-select v-model="fpayForm.fpay_name" size="small" placeholder="请选择费用名称" filterable>
              <el-option v-for="item in fpayAttrList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aname">
            <el-select v-model="fpayForm.cptt_aname" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_name" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="本币金额" prop="scon_rmbtotal">
            <el-input disabled v-model="fpayForm.scon_rmbtotal" maxlength="20" show-word-limit placeholder="请填写本币金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="fpayForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in currencyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="ppay_rmbrate">
            <el-input
              v-model="fpayForm.ppay_rmbrate"
              @change="ppayRmbrateChange"
              @input="val => (fpayForm.ppay_rmbrate = keep4Decimal(val))"
              maxlength="7"
              show-word-limit
              placeholder="请填写汇率"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="出运发票号" prop="tran_no">
            <el-input v-model="fpayForm.tran_no" :autosize="{ minRows: 2, maxRows: 6 }" disabled placeholder="请填写出运发票号" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="增值发票号" prop="fpay_actu_nos">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              disabled
              v-model="fpayForm.fpay_actu_nos"
              maxlength="255"
              show-word-limit
              placeholder="请填写更改项目"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--      <el-row class="vg_mb_5">-->
      <!--        <el-col :md="24">-->
      <!--          <el-form-item label="备注" prop="fpay_remarks">-->
      <!--            <el-input-->
      <!--              type="textarea"-->
      <!--              :autosize="{ minRows: 2, maxRows: 6 }"-->
      <!--              v-model="fpayForm.fpay_remarks"-->
      <!--              maxlength="255"-->
      <!--              show-word-limit-->
      <!--              placeholder="请填写备注"-->
      <!--            >-->
      <!--            </el-input>-->
      <!--          </el-form-item>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="dialogTableVisible1 = true">导入源单信息</el-button>
            <el-button type="danger" plain size="small" @click="delCostDetail">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <CostDetailList @nosChnage="nosChnage" :fpayForm="fpayForm" @handleSelectionChange="handleSelectionChange"></CostDetailList>
        </el-col>
      </el-row>
      <el-dialog title="导入收款单位" width="70%" :visible.sync="dialogTableVisible">
        <InstList @importInstChange="importInstChange"></InstList>
      </el-dialog>
      <el-dialog title="导入源单类型" width="70%" :visible.sync="dialogTableVisible1">
        <TranList @childChanel="childChanel" @childConfirm="childConfirm"></TranList>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { fpayAPI } from '@api/modules/fpay';
import { cpttAPI } from '@api/modules/comptitle';
import { stffAPI } from '@/api/modules/staff';
import { optnAPI } from '@api/modules/optn';
import helper from '@assets/js/helper';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import CostDetailList from './Component/CostDetailList.vue';
import InstList from './Component/InstList.vue';
import TranList from './Component/TranList.vue';
import { BigNumber } from 'bignumber.js';
import { requiredReminder } from '@assets/js/domUtils';
import { keep4Decimal } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'FpayAddMain',
  components: {
    addHeader,
    inputUser,
    CostDetailList,
    InstList,
    TranList
  },
  data() {
    return {
      rules: {
        inst_cname: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_stff_id: [{ required: true, trigger: 'blur', message: ' ' }],
        fpay_date: [{ required: true, trigger: 'blur', message: ' ' }],
        fpay_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      fpayForm: {
        inst_cname: null,
        cust_stff_id: this.$cookies.get('userInfo').stff_id,
        fpay_date: new Date(),
        fpay_total: null,
        fpay_name: 1,
        cptt_aname: '上海全服国际贸易有限公司',
        scon_rmbtotal: null,
        cust_currency: 'RMB',
        ppay_rmbrate: '1.0000',
        tran_no: '',
        fpay_actu_nos: null,
        fpay_fee_list: []
      },
      stffList: [],
      cpttList: [],
      fpayAttrList: [
        { id: 1, label: '包干费' },
        { id: 2, label: '测试费' }
      ],
      currencyList: [],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      selectList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    initData() {
      this.getCptt();
      this.getStffUser();
      this.getCustDport();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate((valid, object) => (valid ? this.saveInfoChange() : requiredReminder(object)));
        })
        .catch(() => {});
    },
    //判断增值税发票号是否重复
    saveInfoChange() {
      let fpayForm = JSON.parse(JSON.stringify(this.fpayForm));
      let fpay_actu_nos_list = Array.from(fpayForm.fpay_fee_list, ({ fpay_actu_nos }) => fpay_actu_nos).join(',');
      get(fpayAPI.getFpayFeeByActuNos, { fpay_actu_nos_list: fpay_actu_nos_list })
        .then(data => {
          if (data.data.code === 999) {
            this.$confirm(data.data.msg, '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning'
            })
              .then(() => {
                this.saveInfo();
              })
              .catch(() => {});
          } else {
            this.saveInfo();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let fpayForm = JSON.parse(JSON.stringify(this.fpayForm));
      fpayForm = Object.assign(fpayForm, staffForm);
      fpayForm.fpay_date = parseInt(Number(new Date(fpayForm.fpay_date).getTime()) / 1000);
      post(fpayAPI.addFpay, fpayForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/fpay_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('fpayForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('fpayForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 更换费用经办人
    custStffChange() {
      for (let i = 0; i < this.stffList.length; i++) {
        if (this.stffList[i].stff_id === this.fpayForm.cust_stff_id) {
          this.fpayForm.cust_stff_name = this.stffList[i].stff_name;
        }
      }
    },
    // 选择收款单位
    importInstChange(val) {
      this.fpayForm.inst_cname = val.inst_abbr;
      this.dialogTableVisible = false;
    },
    // 删除
    delCostDetail() {
      if (this.selectList.length > 0) {
        let temp = [];
        for (let i = 0; i < this.selectList.length; i++) {
          for (let j = 0; j < this.fpayForm.fpay_fee_list.length; j++) {
            if (this.selectList[i].key === this.fpayForm.fpay_fee_list[j].key) {
              temp.push(j);
            }
          }
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let i of temp) {
          this.fpayForm.fpay_fee_list.splice(i, 1);
        }
        for (let i = 0; i < this.fpayForm.fpay_fee_list.length; i++) {
          this.fpayForm.fpay_fee_list[i].key = Number(i);
        }
        this.nosChangeConfirm();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    // 更改汇率
    ppayRmbrateChange() {
      let totalB = new BigNumber(1);
      totalB = totalB.times(this.fpayForm.fpay_total ? this.fpayForm.fpay_total : 0).times(this.fpayForm.ppay_rmbrate ? this.fpayForm.ppay_rmbrate : 0);
      this.fpayForm.scon_rmbtotal = totalB.toFixed(2);
    },
    // 字表回调
    nosChnage() {
      this.nosChangeConfirm();
    },
    nosChangeConfirm() {
      let temp = [];
      let temp1 = [];
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(1);
      for (let i = 0; i < this.fpayForm.fpay_fee_list.length; i++) {
        if (this.fpayForm.fpay_fee_list[i].tran_no) {
          if (temp.indexOf(this.fpayForm.fpay_fee_list[i].tran_no) === -1) {
            temp.push(this.fpayForm.fpay_fee_list[i].tran_no);
          }
        }
        if (this.fpayForm.fpay_fee_list[i].fpay_actu_nos) {
          if (temp1.indexOf(this.fpayForm.fpay_fee_list[i].fpay_actu_nos) === -1) {
            temp1.push(this.fpayForm.fpay_fee_list[i].fpay_actu_nos);
          }
        }
        totalA = totalA.plus(this.fpayForm.fpay_fee_list[i].fpay_fee_pay ? this.fpayForm.fpay_fee_list[i].fpay_fee_pay : 0); //费用金额
      }
      totalB = totalB.times(totalA).times(this.fpayForm.ppay_rmbrate ? this.fpayForm.ppay_rmbrate : 0);
      this.fpayForm.tran_no = temp.join(',');
      this.fpayForm.fpay_actu_nos = temp1.join(',');
      this.fpayForm.fpay_total = totalA.toFixed(2);
      this.fpayForm.scon_rmbtotal = totalB.toFixed(2);
    },
    // 选择删除值
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    // 导入源单类型弹框
    childChanel() {
      this.dialogTableVisible1 = false;
    },
    // 导入源单类型
    childConfirm(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.fpayForm.fpay_fee_list = this.fpayForm.fpay_fee_list.concat(value);
      for (let i = 0; i < this.fpayForm.fpay_fee_list.length; i++) {
        this.fpayForm.fpay_fee_list[i].key = Number(i);
      }
      this.nosChangeConfirm();
      this.dialogTableVisible1 = false;
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.currencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
