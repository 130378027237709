<template>
  <div class="vg_button_group" style="display: flex">
    <el-button type="primary" @click="submit()" size="small">保存</el-button>
    <el-button @click="cancel" size="small">取消</el-button>
    <div class="flexHV" style="margin-left: auto">
      <slot name="otherButton" />
      <div class="flexHV" v-if="!special">
        <span class="vg_tag_label">状态：</span>
        <el-tag type="info">草拟</el-tag>
      </div>
      <div class="flexHV" v-else>
        <slot name="specialStatus" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addHeader',
  props: {
    special: Boolean
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped></style>
